<template>
  <div
    class="overflow-hidden position-relative hp-bg-black-bg pt-24 px-24 pb-18"
    style="border-radius: 15px"
  >
    <div class="position-absolute" style="height: 90%; bottom: 0; right: -30px">
      <svg class="w-100 h-100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M384.797 85.179 194.619 10.257l46.816 86.462L113.23 44.63l129.884 138.803L69.597 116.68l60.469 87.899L2.651 171.657"
          stroke="url(#CreditCardNew)"
          stroke-width="20"
          stroke-linejoin="bevel"
        ></path>
        <defs>
          <linearGradient
            id="CreditCardNew"
            x1="166.04"
            y1="17.382"
            x2="209.529"
            y2="191.807"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff"></stop>
            <stop offset="0.737" stop-color="#fff" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <b-row align-h="between" class="flex-column" style="min-height: 158px">
      <b-col cols="12">
        <svg
          width="54"
          height="61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.032 12 31.54 1.646c-2.856-1.69-6.39-1.69-9.276 0L4.801 12.001C1.945 13.69.178 16.83.178 20.242v20.65c0 3.382 1.767 6.522 4.623 8.243L22.294 59.49c2.856 1.69 6.39 1.69 9.276 0l17.492-10.355c2.856-1.691 4.623-4.83 4.623-8.242V20.243c-.03-3.412-1.796-6.522-4.653-8.242Z"
            fill="#fff"
          ></path>
          <path
            d="M46.48 23.291c-1.671-.474-2.4-.545-2.4-.545-.283-.048-1.39-.237-2.895-.285-1.2-.19-2.448-.522-3.272-1.21-.894-.759-2.4-2.064-2.988-2.372-.024-.024-.047-.024-.071-.024a13.54 13.54 0 0 0-7.978-2.585 13.72 13.72 0 0 0-8.872 3.226 42.295 42.295 0 0 0-2.07 1.732c-.824.711-2.072 1.02-3.272 1.21-1.506.047-2.612.26-2.894.284 0 0-.66.071-2.213.498-.682.19-.753 1.139-.117 1.447 0 0 .023 0 .023.024 1.012.498 1.977 1.305 3.177 3.226 1.436 2.277 1.695 3.724 2.613 5.907 1.482 6.263 7.012 10.912 13.602 10.912 6.354 0 11.72-4.317 13.437-10.224 1.177-2.586 1.342-4.08 2.918-6.595 1.2-1.921 2.165-2.728 3.177-3.226.07-.024.142-.071.212-.095.588-.26.518-1.139-.118-1.305Zm-24.993 10.96c-1.882-.522-3.06-2.277-3.06-2.277s2.025-1.495 3.907-.973c1.883.522 2.918 2.847 2.918 2.847s-1.882.925-3.765.403Zm11.202 0c-1.883.522-3.765-.427-3.765-.427s1.012-2.325 2.918-2.847c1.882-.522 3.906.973 3.906.973s-1.176 1.803-3.059 2.301Z"
            fill="#111314"
          ></path>
        </svg>
      </b-col>

      <b-col cols="12" class="mb-n16 text-right">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          class="hp-text-color-black-0"
          height="64"
          width="64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M22.222 15.768l-.225-1.125h-2.514l-.4 1.117-2.015.004a4199.19 4199.19 0 0 1 2.884-6.918c.164-.391.455-.59.884-.588.328.003.863.003 1.606.001L24 15.765l-1.778.003zm-2.173-2.666h1.62l-.605-2.82-1.015 2.82zM7.06 8.257l2.026.002-3.132 7.51-2.051-.002a950.849 950.849 0 0 1-1.528-5.956c-.1-.396-.298-.673-.679-.804C1.357 8.89.792 8.71 0 8.465V8.26h3.237c.56 0 .887.271.992.827.106.557.372 1.975.8 4.254L7.06 8.257zm4.81.002l-1.602 7.508-1.928-.002L9.94 8.257l1.93.002zm3.91-.139c.577 0 1.304.18 1.722.345l-.338 1.557c-.378-.152-1-.357-1.523-.35-.76.013-1.23.332-1.23.638 0 .498.816.749 1.656 1.293.959.62 1.085 1.177 1.073 1.782-.013 1.256-1.073 2.495-3.309 2.495-1.02-.015-1.388-.101-2.22-.396l.352-1.625c.847.355 1.206.468 1.93.468.663 0 1.232-.268 1.237-.735.004-.332-.2-.497-.944-.907-.744-.411-1.788-.98-1.774-2.122.017-1.462 1.402-2.443 3.369-2.443z"
            ></path>
          </g>
        </svg>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
};
</script>
